<template>
  <div v-if="questions.length">
    <footer class="font-small application-footer">
      <div class="text-center py-3">
        Maat © {{ new Date().getFullYear() }} | Todos os direitos reservados
      </div>
    </footer>
    <flow-form
      ref="flowform"
      @submit="onSubmit"
      :language="language"
      :progressbar="false"
      :navigation="false"
      :standalone="false"
    >
      <template v-for="(question, index) in questions">
        <question
          type="multiplepicturechoice"
          tagline="Em uma escala de 0 a 10"
          :key="index"
          v-bind="question"
          v-if="question.type === 'multiplepicturechoice'"
        ></question>
        <question
          v-else
          v-bind="question"
          :key="index"
          v-model="question.model"
        />
      </template>

      <template v-slot:complete>
        <div class="f-section-wrap">
          <div>
            <div v-if="loading">
              <span class="fh2">Aguuarde um momento...</span>
            </div>
            <div v-else>
              <span class="fh2">
                {{ thankYouText }}
              </span>
              <p class="f-description">
                <span>{{ description }}</span>
              </p>
            </div>
          </div>
        </div>
      </template>
    </flow-form>
  </div>
</template>

<script>
import FlowForm, { LanguageModel, Question } from "@ditdot-dev/vue-flow-form";
import { api } from "@/services/api";

export default {
  components: {
    FlowForm,
    Question,
  },
  props: {
    formId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      questions: [],
      description: "",
      thankYouText: "",
      language: new LanguageModel({
        continue: "Avançar",
        pressEnter: "Pressione ENTER",
        otherPrompt: "Próximo",
        submitText: "Enviar questionário e finalizar",
        skip: "Pular",
        ok: "Avançar",
        multipleChoiceHelpTextSingle: "Selecione uma das opções",
        thankYouText: "Obrigado por responder.",
        successText: "Suas respostas foram enviadas com sucesso!",
        percentCompleted: ":percent% completo",
        longTextHelpText:
          "Aperte SHIFT + ENTER para começar a escrever na próxima linha",
      }),
    };
  },
  methods: {
    getQuestion() {
      api
        .get(`form/${this.formId}/`)
        .then(({ data: { questions, description, thankYouText } }) => {
          this.questions = questions.filter((data) => {
            if (data.descriptionLink === null) {
              data.descriptionLink = [];
            }
            return data;
          });
          this.description = description;
          this.thankYouText = thankYouText;
        });
    },
    onSubmit() {
      this.$emit("submited_questionaire");
    },
  },
  mounted() {
    this.getQuestion();
  },
};
</script>
